@import '../app/scss/theme';
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: $font-family;
	font-size: $font-size-regular;
}

html {
	scroll-behavior: smooth;
	font-size: $font-size-regular;
}

body {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	min-width: 320px;
	background: $dark-mode-white;
	font-size: $font-size-rem-xs;
	line-height: 1.4285em;
	color: rgba(0, 0, 0, 0.87);
	letter-spacing: 0.03em;
}
