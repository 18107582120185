@import '../scss/fonts';
@import '../scss/variables';

hr {
	margin: 0;
	border: none;
	border-top: 1px solid #e6e6e6;
}

button {
	cursor: pointer;
}

.menuText {
	width: $full-width;
	min-height: 60px;
	font-family: $font-family;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 28px;
	padding: 14px 16px;
	display: flex;
	align-items: center;
	color: $black-200;
	background: $dark-mode-white;
	border: none;
}

.badge {
	font-family: $font-family;
	font-size: $font-size-rem-xxxs;
	font-weight: $font-weight-semibold;
	padding: 0px 5px;
	min-width: 3em;
	text-align: center;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-transform: capitalize;
	color: $dark-mode-white;
	margin-right: 5px;
	word-break: break-word;
	background-color: $black-300;
	line-height: 19px;
	margin-bottom: 8px;
}

.badgePopular {
	background-color: $badge-bgcolor-purple;
}

.badgeNew {
	background-color: $badge-bgcolor-green;
}

.badgeSpicy {
	background-color: $badge-bgcolor-red;
}

.badgeGray {
	background-color: $badge-bgcolor-gray;
}

.buttonRed {
	background: $active-red;
}
.buttonGreen {
	background: $active-green;
}
.buttonBlue {
	background: $active-blue;
}
