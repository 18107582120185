@import '../../scss/theme';

.scrollSpy {
	padding: 0 1em;
}

.menuBlock {
	display: flex;
	align-items: center;
	justify-content: center;
	position: sticky;
	top: 47px;
	height: 60px;
	background-color: $dark-mode-white;

	.viewCheck {
		display: block;
		width: 104px;
		height: 36px;
		border-radius: 60px;
		color: $dark-mode-white;
		border: none;
		padding: 0px 0px 0px 35px;
		text-align: left;
		font-size: $font-size-xs;
		font-family: $font-family;
		margin-right: 18px;
		background: url('../../../assets/viewCheck.svg') 15% center no-repeat $error-btn;
	}
}

.menuErrorContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 40px 16px 0px;
	flex-direction: column;
	text-align: center;
	color: $black-200;
	.menuErrorIcon {
		width: 100%;
		.menuErrorImg {
			width: 50px;
			height: 50px;
		}
	}
	.menuErrorMsg {
		margin-top: 10px;
		font-size: $font-size-m;
		font-family: $font-family;
		font-weight: $font-weight-regular;
		line-height: 24px;
	}
}
