@import '../../../scss/theme.scss';

.subModifierName {
	font-style: $font-style-normal;
	font-weight: $font-weight-normal;
	font-size: $font-size-rem-m;
	line-height: 44px;
	color: $black-200;
}

.mainBlock {
	.modifierBlock {
		&:last-child {
			margin-bottom: 14px;
		}
	}
	.selectionBlock {
		min-height: 45px;
		display: flex;
		align-items: baseline;
		justify-content: center;
		width: 100%;
	}
}

.labelName {
	font-style: $font-style-normal;
	font-weight: $font-weight-normal;
	font-size: $font-size-rem-l;
	line-height: 44px;
	color: $mods-label;
	line-height: 26px;
	font-family: $font-family;
	word-break: break-word;
	width: $modifier-label-width;
}

.labelBlock {
	min-height: 40px;
	width: $modifier-label-width;
	border-bottom: 1px solid $mods-gray;
	word-break: break-word;
	overflow: hidden;
	padding-bottom: 5px;
	display: flex;
	align-items: flex-end;
}

.radioButton,
.checkboxButton {
	font-style: $font-style-normal;
	font-weight: $font-weight-normal;
	font-size: $font-size-rem-xxl;
	line-height: 44px;
	color: $radio-btn;
	width: 20px;
	height: 18px;
	position: relative;
	margin-right: 10px;
	margin-left: 16px;
	top: 3px;
}

.modifierBody {
	display: block;
	.modifierHeader {
		padding-left: 50px;
	}
	.modifierBody {
		div {
			.selectionBlock {
				padding-left: 32px;
			}
		}
		.mainBlock {
			.labelBlock {
				border-bottom: inherit;
			}
		}

		.modifierBody {
			.modifierHeader {
				padding-left: 80px;
			}
			.modifierBody {
				.selectionBlock {
					padding-left: 82px;
				}
			}
		}
	}
}

.hideModifierBody {
	display: none;
}

.cartIconStyle {
	padding: 0px 10px;
	background-color: $dark-mode-white;
	outline: none;
	border: none;
	cursor: pointer;
	position: relative;
	top: 1px;
}

.modifierDivider {
	position: absolute;
	height: 1px;
	background: $mods-gray;
	width: calc($full-width - $modifier-border-offset);
	right: 0;
}

.modifierHeader {
	min-height: 60px;
	background: $white-200;
	padding: 6px 16px;
	display: flex;
	position: sticky;
	.modifierHeader {
		padding: 6px 32px;
	}
}

.modifierHeaderLeftBlock {
	text-align: left;
	display: inline-block;
	flex: 1;
	width: 85%;
	.modifierName {
		word-break: break-word;
	}
}

.modifierHeaderRightBlock {
	display: inline-block;
	position: relative;
	top: 0px;
}

.modifierName {
	font-style: $font-style-normal;
	font-weight: $font-weight-bold;
	font-size: $font-size-rem-l;
	color: $mods-label;
	position: relative;
	font-family: $font-family;
	line-height: 26px;
}

.modifierDisabled {
	opacity: 0.8;
	cursor: not-allowed;
}

.modifierChoice {
	font-style: $font-style-normal;
	font-weight: $font-weight-regular;
	font-size: $font-size-xs;
	color: $black-300;
	font-family: $font-family;
	line-height: 21px;
}

.modifierRequired {
	font-family: $font-family;
	font-style: $font-style-normal;
	font-weight: $font-weight-normal;
	font-size: $font-size-rem-xxs;
	text-align: right;
	color: $mods-required;
	line-height: 20px;
	padding-left: 10px;
}

.modifierOptional {
	font-style: $font-style-normal;
	font-weight: $font-weight-normal;
	font-size: $font-size-rem-xxs;
	line-height: 20px;
	text-align: right;
	color: $black-300;
	position: relative;
	padding-top: 2px;
}

.modifierSelected {
	font-style: $font-style-normal;
	font-weight: $font-weight-semibold;
	font-size: $font-size-rem-xxs;
	text-align: right;
	color: $mods-selected;
	line-height: 20px;
	padding-left: 10px;
	padding-top: 11px;
	span {
		position: relative;
		bottom: 6px;
		font-size: $font-size-rem-xxs;
	}
	svg {
		margin-right: 4px;
	}
}

.modifierMainBlock {
	width: 100%;
	height: 100%;
	z-index: -1;
	margin-bottom: 10px	;
	.modifierMainBlock {
		.modifierBlock {
			&:last-child {
				margin-bottom: 0px;
			}
		}
	}
}

.modifierUnavailable {
	color: $mods-required;
	background-color: $dark-mode-white;
}
