@import '../../scss/theme';
.placeholder.container {
	margin: 8px;
}

.header.imgContent {
	width: 100px;
	height: 210px;
}

.line.very.long.navbarContent {
	margin: 30px;
}

.imgLoader {
	height: 80px;
	width: 80px;
	background: $modifier-gray;
}

.imgHeader {
	height: 210px;
	background: $modifier-gray;
	margin: 10px;
}

.navLoader {
	height: 25px;
	background: $modifier-gray;
	margin: 10px;
}

.placeHolderLoader {
	border-radius: 4px;
	position: relative;
	overflow: hidden;
	&:before {
		content: '';
		display: block;
		position: absolute;
		left: -150px;
		top: 0;
		height: 100%;
		width: 200px;
		background: linear-gradient(to right, transparent 0%, $loader-gray 50%, transparent 100%);
		animation: scanner 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
	}
}

.placeHolderLoader {
	&:before {
		content: '';
		display: block;
		position: absolute;
		left: -150px;
		top: 0;
		height: 100%;
		width: 200px;
		background: linear-gradient(to right, transparent 0%, $loader-gray, 50%, transparent 100%);
		animation: scanner 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
	}

	.marginBottom {
		margin-bottom: 10px;
	}
}
.lineShort {
	min-height: 10px;
	max-width: 25%;
	background: $modifier-gray;
}

.lineMedium {
	min-height: 10px;
	max-width: 50%;
	background: $modifier-gray;
}

.lineLong {
	min-height: 10px;
	max-width: 75%;
	background: $modifier-gray;
}

@keyframes scanner {
	0% {
		transform: translateX(-20%);
	}
	100% {
		transform: translateX(170%);
	}
}

.container {
	display: flex;
	padding: 10px;
}

.linesContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 10px;
}

.menuLoader {
	height: 30px;
	background: $modifier-gray;
	margin-left: 10px;
	width: 35%;
}
