@import '../../scss/theme';
svg {
	display: inline-block;
	vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-family: $font-family;
	margin-block-start: 0;
	margin-block-end: 0;
}

h1 {
	font-weight: $font-weight-bolder;
	font-size: $font-size-l;
	line-height: 1;
	margin: 0.45em 0 0.45em 0.635em;
	display: inline-block;
	vertical-align: top;
}

header {
	background-color: $dark-mode-white;
	background-position: center center;
	background-size: cover;
	height: 210px;

	.logo {
		transition: 0.4s;
		margin: 0 1em;
		padding: 0.75em 0;
		align-items: center;
		display: flex;
	}

	.storeLogo {
		background: $dark-mode-white;
		padding: 0.45em 5px;
		border-radius: 50%;
		width: 46px;
		height: 46px;
		margin-top: 130px;
	}
}

.header {
	color: $light-greyishblue;
	position: sticky;
	top: 0;
	.restaurantDetails {
		padding: 0.65em 1em;
		font-family: $font-family;
		border-bottom: 1px solid $light-gray;
		padding-top: 12px;
		padding-bottom: 8px;
	}

	.restaurantName {
		font-family: $font-family;
		font-style: normal;
		font-weight: $font-weight-bold;
		font-size: $font-size-xxl;
		line-height: 30px;
		display: flex;
		align-items: center;
		text-align: left;
		color: $light-greyishblue;
		margin: 0px;
		margin-bottom: 0.15em;
		letter-spacing: 0.1px;
	}

	.restaurantAddress {
		font-size: $font-size-xs;
		font-weight: $font-weight-normal;
		line-height: 21px;
		margin-top: 0.5em;
		color: $header-address;
		text-align: left;
	}

	.restaurantContact {
		margin-left: 0.75em;
		font-size: $font-size-s;
	}
}

.scrolled {
	transition: all 0.5s ease;
	animation: hideHeader 2s forwards;
}

@keyframes hideHeader {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.fixedHeader {
	border-bottom: 1px solid $transparent-black;
	padding: 0px;
	background-image: none;
	background-color: $dark-mode-white;
	border-bottom: 1px solid $light-grey;
	height: $full-height;
	width: $full-width;
	position: sticky;
	max-height: 89px;
	top: 0;
	box-shadow: 0px 2px 8px 1px $transparent-black;
	overflow: hidden;
	z-index: 99;

	.logo {
		margin: 40px 18px;
		align-items: center;
		display: flex;
		color: $black-100;
		text-decoration: none;
	}

	.menuLogo {
		background: $dark-mode-white;
		border-radius: 8px;
		max-width: 26px;
	}

	.navbarRight {
		display: inline;
		visibility: visible;
		font-size: $font-size-l;
		font-weight: $font-weight-bold;
		margin-left: 10px;
		letter-spacing: 0px;
		font-family: $font-family;
	}
}
