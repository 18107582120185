@import '../../scss/theme';

.categoriesNav {
	width: 100%;
	font-size: $font-size-xs;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: sticky;
	top: 107px;
	background: $white-100;
	border-top: 1px solid $light-grey;
	line-height: 21px;

	.active {
		font-size: $font-size-xs;
		width: auto;
		border: none;
		outline: none;
		border-bottom: 4px solid $light-greyishblue;
		font-weight: $font-weight-bold;
		text-decoration: none;
		color: $light-greyishblue;
		padding: 9px 0 8px 0;
		margin: 0 24px 0 0;
		background: transparent;
		line-height: 21px;
		align-items: center;
	}

	.scrollingNav {
		overflow-y: hidden;
		overflow-x: scroll;
		white-space: nowrap;
		margin: 0 1em;

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent;
		}

		::-webkit-scrollbar-thumb {
			background: $white-100;
		}
	}

	.categoryName {
		font-family: $font-family;
		font-size: $font-size-xs;
		font-weight: $font-weight-regular;
		border: none;
		background-color: transparent;
		margin: 0 24px 0 0;
		text-align: center;
		height: 42px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: $light-greyishblue;
		line-height: 21px;
	}

	.category a {
		color: $black-100;
	}
}

.categoryInfo {
	font-family: $font-family;
	padding: 16px 0px 10px 0px;

	.categoryName {
		text-align: left;
		font-size: $font-size-l;
		font-weight: $font-weight-bold;
		line-height: 26px;
		color: $light-greyishblue;
	}

	.categoryDesc {
		text-align: left;
		line-height: 21px;
		font-size: $font-size-xs;
		color: $black-300;
		font-weight: $font-weight-regular;
	}
}

.categorisedItems {
	padding: 0px 16px 50vh 16px;
}
