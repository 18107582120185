@import '../../scss/theme';

.orderDetails {
	width: 100%;
	padding: 55px 16px;
	background-color: $light-shaded-gray;
	position: relative;
	min-height: 100vh;
	font-family: $font-family;

	.orderPage {
		padding: 0px 16px;
		min-height: 540px;
		margin-bottom: 50px;
	}
	.form {
		.formField {
			margin-bottom: 0px !important;
		}
	}
	.navButton {
		button {
			padding: 0px !important;
			background-color: transparent !important;
			font-size: $font-size-l;
			width: 100%;
			i {
				opacity: 1 !important;
				color: $nav-btn !important;
			}
			.backButton,
			.lessThanButton {
				background-color: transparent;
			}
		}
	}
	.logo {
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		.restaurantLogo {
			border: 1px solid $mods-gray;
			background: $dark-mode-white;
			border-radius: 50%;
			width: 130px;
			height: 130px;
		}
	}

	.textCentered {
		text-align: center;
	}
	.restaurantName {
		text-align: center;
		font-weight: $font-weight-semibold;
		font-size: $font-size-rem-xl;
		line-height: 28px;
		margin-block-start: 9px;
		margin-block-end: 20px;
		color: $black-200;
	}
	.nameLabel {
		font-weight: $font-weight-bold;
		font-size: $font-size-rem-xl;
		color: $deep-blue;
		line-height: 28px;
		margin-block-start: 21px;
		margin-block-end: 7px;
	}
	.tableNo {
		font-weight: $font-weight-bold;
		font-size: $font-size-m;
		line-height: 23px;
		margin-block-start: 0px;
		margin-block-end: 19px;
		color: $deep-blue;
	}
	.deliveryMessage {
		font-weight: $font-weight-regular;
		line-height: 22px;
		font-size: $font-size-rem-1xs;
		margin-block-start: 0px;
		margin-block-end: 19px;
		color: $black-200;
	}
	.smallText {
		font-weight: $font-weight-regular;
		line-height: 20px;
		font-size: $font-size-rem-xxs;
		margin-block-start: 4px;
		margin-block-end: 20px;
		color: $black-200;
	}
	.label {
		font-weight: $font-weight-bold;
		font-size: $font-size-xs !important;
		line-height: 21px;
		color: $mods-label !important;
	}
	.textBox {
		font-weight: $font-weight-regular;
		font-size: $font-size-rem-xm !important;
		line-height: 25px !important;
		color: $black-200 !important;
	}

	.confirmationLink {
		text-align: center;
		display: block;
		margin-block-start: 36px;
		margin-block-end: 4px;
		color: $black-200;
		font-size: $font-size-rem-1xs;
		font-weight: $font-weight-regular;
		line-height: 22px;

		.receiveLink {
			font-size: $font-size-rem-1xs;
		}
		.resendText {
			font-size: $font-size-rem-1xs;
			font-weight: $font-weight-bold;

			.resendBtn {
				margin-left: 5px;
				background: transparent;
				text-decoration: underline;
				border: none;
				font-size: inherit;
				font-weight: inherit;
			}
		}
	}
	.shift4Logo {
		width: 100px;
		height: 46px;
		margin: 0 auto;
		background: $logo-background;
		margin-bottom: 10px;
	}

	.fixedBottom {
		position: absolute;
		bottom: auto;
		left: 50%;
		transform: translate(-50%, 0);
		background-color: transparent;
	}
	.ui.primary.button,
	.ui.primary.buttons .button {
		background-color: $primary-btn !important;
		border-radius: 6px;
	}
	.continueBtn {
		background-color: $primary-btn !important;
		height: 48px;
		font-size: $font-size-m;
		padding: 16px;
	}
}

#root {
	background-color: $shaded-gray;
}
