@import '../../scss/theme';
.footer {
	width: $full-width;
	position: fixed;
	padding: 10px;
	bottom: -1px;
	background: $dark-mode-white;
	border-top: 1px solid $transparent-black;
	padding-right: 16px;
	padding-left: 16px;
	z-index: 100;
}
.quantityIcon {
	height: 26px;
}
.quantityContainerDiv {
	display: flex;
	padding-right: 28px;
	width: calc(20% + 56px);
	height: 44px;
	line-height: 44px;
}
.quantityIconDiv {
	line-height: 60px;
	padding: 0px 5px;
}
.btnDiv {
	position: relative;
	left: 47%;
}
.btnStyle {
	position: relative;
	width: 80%;
	border-radius: 8px;
	font-family: $font-family;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	height: 44px;
	align-items: center;
	text-align: center;
	color: $dark-mode-white;
	border: none;
}

.quantityText {
	font-family: $font-family;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 44px;
	display: inline-block;
	align-items: center;
	text-align: center;
	color: $light-shaded-black;
	width: 36px;
	height: 44px;
	background: $bright-gray;
	border-radius: 11px;
	border: none;
}
input:focus-visible {
	outline-offset: 0px;
}
:focus-visible {
	outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type='number'] {
	-moz-appearance: textfield; /* Firefox */
}
::-moz-selection {
	color: $light-shaded-black;
	text-shadow: none;
	background: $bright-gray;
}
::selection {
	color: $light-shaded-black;
	text-shadow: none;
	background: $bright-gray;
}
.plusOpacity {
	opacity: 0.4;
}
.errorMessage {
	font-size: 16px;
	line-height: 18px;
	font-family: Inter;
	font-style: normal;
	color: $required;
	background: $dark-mode-white;
	text-align: center;
}
.errorBorder {
	border: 1px solid $required;
	box-sizing: border-box;
	border-radius: 4px;
}
.fullWidth {
	width: $full-width;
}

.buttonRed {
	background-color: $error-btn;
}

.balanceDueRow {
	display: block;
	width: 100%;
	padding-bottom: 10px;
	.balanceDueName,
	.balanceDueValue {
		font-weight: $font-weight-bold;
	}
}

.balanceDueName {
	width: 80%;
	text-align: left;
	display: inline-block;
	font-size: $font-size-rem-m;
	line-height: 24px;
}

.balanceDueValue {
	width: 20%;
	text-align: right;
	display: inline-block;
}