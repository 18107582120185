@import '../../scss/theme';

.errorSection {
	position: fixed;
	top: 0 !important;
	left: 0 !important;
	width: 100%;
	height: 100%;
	text-align: center;
	vertical-align: middle;
	padding: 1em;
	background-color: $modal-background;
	line-height: 1;
	font-family: $font-family;

	.errorModal {
		position: absolute;
		z-index: 1001;
		text-align: left;
		background: $dark-mode-white;
		border: none;
		width: 90%;
		margin: 0 auto;
		top: 50%;
		transform: translate(-50%, -50%);
		border-radius: 4px;
		left: 50%;
		height:212px;
		.modalHeader {
			font-size: $font-size-rem-xl;
			text-align: center;
			font-weight: $font-weight-bold;
			padding: 24px 0px 12px 0px;
			color: $black-100;
		}
		.modalBody {
			.modalContent {
				padding: 0px 16px 24px 16px;
				font-size: $font-size-m;
				line-height: 24px;
				text-align: center;
			}
			.closeBtn {
				width: 90%;
				margin: 0px auto 16px auto;
				border-radius: 6px;
				min-height: 52px;
			}
		}
	}
}

.errorContainer {
	width: 100%;
    margin: 0 auto;
	color: #ffffff;

	.apiErrorBackicon {
		position: absolute;
		padding: 30px 20px;

		.apiErrorBackButton {
			background: #cd2026;
			border: none;
		}
	}

	.apiErrorSection {
		height: 100vh;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		display: flex;
		background-color: #cd2026;

		img {
	vertical-align: middle;
			border-style: none;
		}

		.errorIcon {
			margin-bottom: 1rem;
			padding-bottom: 0.25rem;
	}

		.errorTitle {
			line-height: 1.2;
			margin-right: 0.5rem;
			text-align: center;
			margin-left: 0.5rem;
		
			b {
				font-size: 1.375rem;
				font-weight: 700;
	}
	}

		.errorDescription{
			margin-top: 0.5rem;
			margin-left: 0.5rem;
			margin-right: 0.5rem;
			text-align: center;
			font-weight: 400;
			line-height: 22px;
			font-size: 1rem;
	}
}

	.fixedBottom {
		position: fixed;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1030;

		.btnContanier {
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 1.5rem;

			.retryBtn {
				min-height: 38px;
				color: $error-text;
				background-color: $light-shaded-gray;
				border-radius: 4px;
				border: none;
				width: 27%;
				padding: 10px 14px;
				font-weight: 600;
				word-wrap: break-word;
				font-size: 1rem;
			}
		}
	}
}

