@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

// Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-normal: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 900;

// Font family
$font-family: 'Open Sans', sans-serif;

// Font sizes
$font-size-regular: 16px;
$font-size-xxxs: 0.688em;
$font-size-xxs: 0.75em;
$font-size-xs: 0.813em;
$font-size-s: 0.938em;
$font-size-m: 1em;
$font-size-l: 1.125em;
$font-size-xl: 1.25em;
$font-size-xxl: 1.375em;
$font-size-xxxl: 1.5em;
$font-size-xxxxl: 2em;

$font-style-normal: normal;





$font-size-rem-xxxs: 0.688rem;
$font-size-rem-xxs: 0.75rem;
$font-size-rem-xs: 0.8125rem;
$font-size-rem-1xs: 0.875rem;
$font-size-rem-s: 0.9375rem;
$font-size-rem-m: 1rem;
$font-size-rem-xm:1.07rem;
$font-size-rem-l: 1.125rem;
$font-size-rem-xl: 1.25rem;
$font-size-rem-xxl: 1.375rem;
$font-size-rem-xxxl: 1.5rem;
$font-size-rem-xxx3: 2rem;
