@import '../../scss/theme';

.orderConfirmation {
	width: 100%;
	padding: 0px 18px;
	background: $light-shaded-gray;
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-flow: column;
	justify-content: center;
	height: -webkit-fill-available;

	.orderPage {
		padding-top: 12px;
		margin-bottom: 20px;
		min-height: 560px;
	}

	.logo {
		display: flex;
		align-items: center;
		justify-content: center;
		.restaurantLogo {
			background: $dark-mode-white;
			border-radius: 50%;
			width: 130px;
			height: 130px;
			border: 1px solid $mods-gray;
		}
	}

	.textCentered {
		text-align: center;
	}

	.restaurantName {
		text-align: center;
		font-family: $font-family;
		font-weight: $font-weight-semibold;
		font-size: $font-size-rem-xl;
		line-height: 28px;
		margin-block-start: 9px;
		margin-block-end: 0px;
		color: $black-200;
	}

	.divider {
		border-top-color: $instruction-divider !important;
		margin: 20px auto;
	}
	.confirmationBlock {
		text-align: center;
	}
	.successIcon {
		margin-block-start: 0px;
		margin-block-end: 10px;
	}
	.successMsg {
		font-family: $font-family;
		font-weight: $font-weight-semibold;
		line-height: 26px;
		font-size: $font-size-rem-l;
		margin-block-start: 10px;
		margin-block-end: 23px;
		color: $black-200;
		word-break: break-word;
	}
	.orderActions {
		display: flex;
		align-items: center;
		justify-content: center;
		.btn {
			border-radius: 6px;
			font-size: $font-size-rem-m;
			font-weight: $font-weight-bold;
			font-family: $font-family;
			text-align: center;
			height: 48px;
			padding: 16px;
		}
		.orderMoreBtn {
			background: $primary-btn;
			margin-right: 13px;
		}
		.payBtn {
			background: $success-btn;
		}
		.disabled {
			opacity: 0.6;
			cursor: not-allowed;
		}
	}
	.confirmationLink {
		text-align: center;
		display: block;
		margin-block-start: 36px;
		margin-block-end: 4px;
	}
	.shift4Logo {
		width: 100px;
		height: 46px;
		margin: 0 auto;
		background: $dark-mode-white;
		margin-bottom: 10px;
	}

	.fixedBottom {
		background-color: transparent;
	}
}
