@import '../../scss/theme';

$minified-header-height: 132px;

#headerSection {
	background: #fff;
}

.modifierNav {
	width: 100%;
	font-size: $font-size-rem-xs;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-top: 1px solid $white-100;
	border-bottom: 1px solid $border-gray;
	background-color: $dark-mode-white;
	position: sticky;
	z-index: 20;

	.modifierEllipsis {
		width: 200px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.active {
		font-size: $font-size-rem-xs;
		width: auto;
		border: none;
		outline: none;
		border-bottom: 5px solid $light-greyishblue;
		font-weight: $font-weight-bold;
		text-decoration: none;
		color: $light-greyishblue;
		padding: 11px 0 10px 0;
		margin: 0 24px 0 0;
		background: transparent;
		max-width: 200px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.scrollingNav {
		overflow-y: hidden;
		overflow-x: scroll;
		white-space: nowrap;
		padding: 0 1em;

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent;
		}

		::-webkit-scrollbar-thumb {
			background: $white-100;
		}
	}

	.modifierName {
		font-family: $font-family;
		font-size: $font-size-rem-xs;
		font-weight: $font-weight-regular;
		border: none;
		background-color: transparent;
		padding: 11px 0 10px 0;
		margin: 0 24px 0 0;
		text-align: center;
		height: 42px;
		display: inline-block;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		color: $light-greyishblue;
		word-break: break-word;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		max-width: 200px;
		line-height: 21px;
	}
}

.modifierNavWithoutImage {
	width: 100%;
	font-size: $font-size-rem-xs;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-top: 1px solid $white-100;
	border-bottom: 1px solid $border-gray;
	background-color: $dark-mode-white;
	z-index: 20;
	position: sticky;

	.modifierEllipsis {
		width: 200px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.active {
		font-size: $font-size-rem-xs;
		width: auto;
		border: none;
		outline: none;
		border-bottom: 5px solid $light-greyishblue;
		font-weight: $font-weight-bold;
		text-decoration: none;
		color: $light-greyishblue;
		padding: 11px 0 10px 0;
		margin: 0 24px 0 0;
		background: transparent;
		max-width: 200px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.scrollingNav {
		overflow-y: hidden;
		overflow-x: scroll;
		white-space: nowrap;
		padding: 0 1em;

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent;
		}

		::-webkit-scrollbar-thumb {
			background: $white-100;
		}
	}

	.modifierName {
		font-family: $font-family;
		font-size: $font-size-rem-xs;
		font-weight: $font-weight-regular;
		border: none;
		background-color: transparent;
		padding: 11px 0 10px 0;
		margin: 0 24px 0 0;
		text-align: center;
		height: 42px;
		display: inline-block;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		color: $light-greyishblue;
		word-break: break-word;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		max-width: 200px;
		line-height: 21px;
	}
}

// TODO STQR-229+ header transition development will be removed
.modifierScrollSpy {
	// border: 0.3px solid red;
}

.headerSection {
	min-height: 300px;
}

.headerScrolledShow {
	top: -1px;
	transition: all 5s ease-out;
	position: relative;
	height: auto;
	display: flex;
	& > div {
		width: 100%;
		height: 100%;
		min-height: $minified-header-height;
	}
}

.hidden {
	display: none;
}

.minifiedHeaderScrolledShow {
	top: -1px;
	transition: all 5s ease-in;
	position: sticky;
	height: $minified-header-height;
	display: flex;
	& > div {
		width: 100%;
		min-height: $minified-header-height;
	}
}

.modifierSet {
	min-height: 400px;
}

.fullWidth {
	width: 100%;
}

.minHeaderHeight {
	min-height: 131px;
}
