@import '../src/app/scss/theme';
body {
	margin: 0;
	font-family: $font-family;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #fff;
}

code {
	font-family: $font-family;
}
