@import '../../scss/theme';
.i4goFrame {
    background-color: $light-shaded-gray;
    padding-bottom: 5px;
    position: relative;
    z-index: 1;
    padding: 0 16px;
    min-height: 320px;
}

.i4goThreeDsFrame {
  min-height: 370px;
}

.i4goFrameHide {
    display: none;
}

.i4goLoaderContainer {
    position: absolute;
    width: 100%;
    top: 48%;
    z-index: 1;
}

.loader {
    position: absolute;
    border-radius: 50%;
    border: solid 5px #cfd0d1;
    border-bottom-color: #1c87c9;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    left: 45%;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
