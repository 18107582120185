@import '../../scss/theme';

.itemMinifiedHeaderMainBlock {
	overflow: hidden;
	position: fixed;
	top: 0px;
	z-index: 20;
	background-color: $dark-mode-white;
	box-shadow: 0px 2px 8px $transparent-black;
	display: flex;
	padding: 16px 16px 8px 16px;
	height: 126px;
	width: 100%;
	min-height: 126px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	button {
		background-color: transparent !important;
		height: 24px;
		padding: 0px !important;
	}
}

.itemMinifiedHeaderMainBlockNoImage {
	overflow: hidden;
	position: fixed;
	top: 0px;
	z-index: 20;
	background-color: $dark-mode-white;
	box-shadow: 0px 2px 8px $transparent-black;
	display: flex;
	padding: 16px 16px 8px 16px;
	min-height: 82px;
	width: 100%;
	min-height: 126px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	button {
		background-color: transparent !important;
		height: 24px;
		padding: 0px !important;
	}
}

.itemPrice {
	font-family: $font-family;
	font-style: normal;
	font-weight: $font-weight-bold;
	line-height: 21px;
	font-size: $font-size-xs;
	color: $gray-300;
	margin-top: 4px;
}

.itemTitle {
	font-family: $font-family;
	font-style: normal;
	font-weight: $font-weight-bold;
	font-size: $font-size-regular;
	color: $black-200;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	word-break: break-word;
	line-height: 24px;
}

.itemDescription {
	font-family: $font-family;
	font-style: normal;
	font-size: $font-size-xs;
	color: $black-300;
	line-height: 21px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	word-break: break-word;
}

.itemImage {
	height: 70px;
	width: 70px;
}

.itemImageBlock {
	display: inline-block;
	padding-right: 12px;
}

.itemMiniHeader {
	display: flex;
	width: 100%;
	animation: fadein 650ms;
}
@keyframes fadein {
	from { opacity: 0}
	to   { opacity: 1}
  }
.itemMiniHeaderHidden {
	display: flex;
	width: 100%;
}

.itemInformationBlock {
	display: inline-block;
	position: relative;
	top: -4px;
	width: 90%;
}

.itemInformationBlockWithoutImage {
	display: inline-block;
	width: calc(100% - $cancel-icon-width);
	position: relative;
	top: 0px;
}

.itemInformationBlockWithoutImageWithDescription {
	display: inline-block;
	position: relative;
	top: 0px;
	width: 90%;
}

.closeButton {
	display: inline-block;
	padding-left: 16px;
	position: relative;
	top: 0px;
}
