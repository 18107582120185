@import '../../scss/theme.scss';

.ui.message.optionRow {
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	background: $dark-mode-white;
	padding: 12px 16px;
}

.ui.modal > .icon:first-child + *,
.ui.modal > :first-child:not(.icon) {
	border-radius: 0;
	padding: 16px;
}

.ui.modal.modalMenu {
	position: absolute;
	bottom: 0;
	margin: 0;
	padding: 0;
	background: $dark-mode-white;
	width: $full-width;
	box-shadow: none;
}
.headingMenu {
	font-style: normal;
	font-weight: $font-weight-semibold;
	font-size: 20px;
	line-height: 28px;
	color: $mods-label;
}
.optionHeadMenu {
	font-size: 16px;
	font-style: normal;
	font-weight: $font-weight-semibold;
	color: $mods-label;
	line-height: 24px;
}
.optionMenu {
	font-size: 14px;
	color: $black-300;
	line-height: 22px;
}

.divider {
	margin: 0;
	height: 1px;
	background: $border-gray;
	border: none;
	float: right;
	width: calc($full-width - $menu-option_border-offset);
}
.dividerSelected {
	background: $border-gray-selected;
}

.caretDownSpace {
	padding-top: 4px;
	padding-left: 10px;
}
