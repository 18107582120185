@import '../../scss/theme';

.viewDetailContainer {
	width: $full-width;
	padding-bottom: 15px;
	background: $light-shaded-gray;
	min-height: 100vh;
	.footer {
		div {
			background: $light-shaded-gray;
		}
	}
}

.viewDetailHeading {
	font-family: $font-family;
	font-style: $font-style-normal;
	font-weight: $font-weight-semibold;
	font-size: $font-size-rem-xl;
	line-height: 28px;
	text-align: center;
	color: $success-btn;
	text-transform: uppercase;
	position: sticky;
	top: 0;
	background: $light-shaded-gray;
	height: 48px;
	z-index: 99;
	border-bottom: 1px solid $light-grey;
	display: flex;
	justify-content: center;

	.headingTitle {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px 0px;
		font-size: $font-size-rem-xl;
		font-weight: $font-weight-semibold;
		color: $active-green;
	}

	.backButton {
		display: inline-block;
		position: absolute;
		top: 8px;
		left: 11px;
		height: 15px;
		button {
			background-color: transparent !important;
			i {
				font-size: $font-size-m;
				color: $nav-btn !important;
				opacity: 1 !important;
			}
		}
	}
}

.divider {
	margin: 0 auto;
	height: 1px;
	background: $border-gray;
	border: none;
}

.viewContainer {
	padding: 0px 16px 0px 16px;
	.ticketContainer {
		font-family: $font-family;
		font-style: $font-style-normal;
		font-size: 16px;
		line-height: 24px;
		padding: 16px 0px;
		color: $light-shaded-black;
	}

	.itemDetailsContainer {
		font-family: $font-family;
		font-style: $font-style-normal;
		font-size: $font-size-rem-s;
		line-height: 23px;
		color: $mods-label;

		.itemDetailsRow {
			border-bottom: 1px solid $border-gray;
			padding: 18px 0px;
			&:last-child {
				border-bottom: 0px;
			}
		}

		.itemHeader {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.itemName {
				font-weight: $font-weight-bold;
				text-align: left;
				width: 80%;
				font-size: $font-size-rem-s;
				line-height: 23px;
				word-break: break-word;
			}

			.quantity {
				font-weight: $font-weight-bold;
				text-align: left;
				padding: 0px 6px 0px 0px;
			}

			.itemPrice {
				text-align: right;
				font-size: $font-size-rem-s;
				line-height: 23px;
			}
		}

		.itemModifiers {
			padding-left: 16px;
			max-width: 85%;
			font-size: $font-size-rem-1xs;
			line-height: 22px;
			color: $black-300;
		}
	}

	.ticketRowLabelBold {
		width: 60%;
		font-weight: $font-weight-bold;
		font-size: $font-size-rem-m;
		line-height: 24px;
		word-break: break-word;
	}

	.ticketRowLabel {
		width: 60%;
		font-weight: $font-weight-regular;
		font-size: $font-size-rem-m;
		line-height: 24px;
	}

	.ticketRowValue {
		width: 40%;
		text-align: right;
		word-break: break-word;
		font-weight: $font-weight-regular;
		font-size: $font-size-rem-m;
		line-height: 24px;
		color: $black-200;
	}

	.ticketRow {
		width: 100%;
		display: flex;
	}

	.menuItemsTitle {
		font-family: $font-family;
		font-style: $font-style-normal;
		font-weight: $font-weight-bold;
		font-size: 13px;
		line-height: 20px;
		text-transform: uppercase;
		color: $dark-blue;
		padding: 16px;
	}
}

.priceContainer {
	padding: 19px 0px;
	font-family: $font-family;
	font-style: $font-style-normal;
	font-weight: $font-weight-normal;
	font-size: $font-size-m;
	line-height: 1.5em;
	color: $black-200;
	min-height: 300px;

	.row {
		display: block;
		width: 100%;
		.total {
			font-size: $font-size-rem-m;
			line-height: 24px;
			.name,
			.value {
				font-weight: $font-weight-bold;
			}
		}
	}

	.innerRow {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.header {
		width: 100%;
	}

	.subHeader {
		padding-left: 20px;
		text-align: left;
	}

	.hiddenTaxTitle, .taxName, .name {
		width: 80%;
		text-align: left;
		display: inline-block;
		font-size: $font-size-rem-m;
		line-height: 24px;
		font-weight: $font-weight-regular;
	}

	.taxName {
		padding-left: 25px;
	}

	.hiddenTaxTitle {
		padding-left: 10px;
	}

	.taxValue, .value {
		width: 20%;
		text-align: right;
		display: inline-block;
		font-weight: $font-weight-regular;
	}
}

.footer {
	.fullWidth {
		width: 100%;
	}

	.buttonGreen {
		border-radius: 8px;
		color: $white-100;
		border-radius: 6px;
		font-family: $font-family;
		font-style: $font-style-normal;
		font-weight: $font-weight-bold;
		font-size: $font-size-rem-m;
		line-height: 48px;
		align-items: center;
		text-align: center;
		border: none;
		cursor: pointer;
	}
	.disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}
}
.specialRequestContainer {
	margin-bottom: 8px;
}

.specialRequest {
	margin-left: 16px;
	word-break: break-word;
	.modifiersDomLabel {
        margin-left: 16px;
    }
}
