@import '../../scss/theme.scss';

.specialInstruction {
	visibility: hidden;
	width: $full-width;
	color: $black-100;
	font-family: $font-family;
	font-size: $font-size-s;
	font-weight: $font-weight-normal;
	border-top: 1px solid $instruction-divider;
	line-height: inherit;
	padding: 0px 16px 80px 16px;
	text-align: left;

	.instructionLabel {
		text-align: left;
		font-size: $font-size-l;
		font-weight: $font-weight-bold;
		color: $black-200;
		line-height: 26px;
		padding: 8px 0px;
		display: block;
	}

	.instructionTextarea {
		width: $full-width;
		resize: none;
		padding: 8px 12px;
		line-height: 23px;
		height: 86px;
		font-size: $font-size-s;
		color: $black-200;
		border: 1px solid $instruction-gray;
		box-sizing: border-box;
		outline: none;

		&::placeholder {
			color: $instruction-placeholder;
			font-size: $font-size-xs;
		}
	}
}
