@import '../../scss/theme';
.orderDetailContainer {
	width: $full-width;
	background: $light-shaded-gray;
	min-height: 100vh;
	padding-bottom: 75px;
	.footer {
		div {
			background: $light-shaded-gray;
		}
	}
}
.placeOrderBackground {
	background: $light-shaded-gray;
	box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}
.orderDetailHeading {
	position: sticky;
	top: 0;
	background: $light-shaded-gray;
	height: 48px;
	z-index: 1;
	border-bottom: 1px solid $light-grey;
	.headingTitle {
		font-family: $font-family;
		font-weight: $font-weight-semibold;
		line-height: 28px;
		text-align: center;
		color: $dark-blue;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px 0px 10px 0px;
		font-size: $font-size-rem-xl;
		text-transform: uppercase;
		background: $light-shaded-gray;
	}
	.backButton {
		display: inline-block;
		position: absolute;
		left: 10px;
		top: 8px;
		height: 15px;
		button {
			background-color: transparent !important;
			i {
				font-size: $font-size-m;
				color: $nav-btn !important;
				opacity: 1 !important;
			}
		}
	}
}

.divider {
	margin: 0;
	height: 1px;
	background: $border-gray;
	border: none;
	width: $order-detail-divider;
	margin: 0 auto;
}

.detailContainer {
	padding: 0px 16px;
	.ticketContainer {
		font-family: $font-family;
		font-style: $font-style-normal;
		font-size: $font-size-m;
		line-height: 24px;
		color: $light-shaded-black;
		padding: 14px 0px 16px;
		.ticketRowLabel {
			width: 30%;
		}
		.ticketRowValue {
			width: 70%;
		}
	}
	.itemDetailsContainer {
		font-family: $font-family;
		font-style: $font-style-normal;
		font-size: $font-size-xs;
		line-height: 23px;
		color: $black-200;
		padding: 16px 0px 16px 0px;
	}
	.menuItemsTitle {
		font-family: $font-family;
		font-style: $font-style-normal;
		font-weight: $font-weight-bold;
		font-size: $font-size-rem-xs;
		line-height: 21px;
		text-transform: uppercase;
		color: $dark-blue;
		padding-bottom: 5px;
	}
	.ticketRowLabelBold {
		width: $order-detail-labels-width;
		font-weight: $font-weight-bold;
		font-size: $font-size-rem-s;
		line-height: 23px;
		word-break: break-word;
	}
	.ticketRowValueBold {
		width: 20%;
		text-align: right;
		font-weight: $font-weight-bold;
	}
	.ticketRowValue {
		width: 50%;
		text-align: right;
		font-size: $font-size-rem-s;
	}
	.ticketRowTableLabel {
		width: 40%;
		font-size: $font-size-rem-m;
	}
	.ticketRowTableLabelBold {
		width: $order-detail-labels-width;
		font-weight: $font-weight-bold;
		font-size: $font-size-rem-m;
	}
	.ticketRowTableValue {
		width: 60%;
		text-align: right;
		font-size: $font-size-rem-m;
		color: $black-200;
	}
	.ticketRowTableBoldValue {
		width: 50%;
		text-align: right;
		font-size: $font-size-rem-m;
		font-weight: $font-weight-bold;
		color: $black-200;
		line-height: 24px;
	}
	.ticketRow {
		width: 100%;
		display: flex;
	}
}

.modifierSetDomLabel {
	width: $order-detail-labels-width;
	font-weight: $font-weight-semibold;
	font-size: $font-size-rem-s;
	line-height: 23px;
	word-break: break-word;
}

.modifierSetDomContainer {
	margin-left: 16px;
}

.modifiersDomLabel {
	width: $order-detail-labels-width;
	font-weight: $font-weight-normal;
	font-size: $font-size-rem-s;
	line-height: 23px;
	margin-left: 16px;
}

button {
	&.link {
		background-color: transparent !important;
		padding: 0 !important;
		color: $primary-btn !important;
		font-size: $font-size-rem-1xs !important;
		line-height: 22px !important;
		font-weight: $font-weight-semibold !important;
		margin: 8px 0px 16px !important;
	}
}

.specialRequestContainer {
	margin-bottom: 8px;
}

.specialRequest {
	margin-left: 16px;
	word-break: break-word;
}
