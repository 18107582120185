@import '../../scss/theme';

.preAuthContainer {
    background-color: $light-shaded-gray;
}
.preAuthHeaderContainer {
	width: 100%;
	padding: 0px 18px;
	background: $light-shaded-gray;
	position: relative;
	justify-content: center;

	.preAuthHeaderSubContainer {
		padding-top: 12px;
		margin-bottom: 10px;

    .backArrow {
        border: none;
        background: $light-shaded-gray;
        padding: $font-size-rem-xl 2px;
        position: relative;
        z-index: 1;
    }
	}
    .logo {
        position: relative;
        bottom: 3.125rem;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		.restaurantLogo {
			border: 1px solid $mods-gray;
			background: $dark-mode-white;
			border-radius: 50%;
			width: 35px;
			height: 35px;
		}
	}
    .restaurantName {
        position: relative;
        bottom: 2.6875rem;
        font-style: normal;
        font-weight: $font-weight-semibold;
        font-size: 1rem;
        line-height: 1.75rem;
        text-align: center;
        color: $black-200;
    }
    .dividerContainer {
        border-top: 1px solid $gray-100;
        margin: 0 1.25rem;
        position: relative;
        bottom: 2.5rem;
    }
    .enterDetailsText {
    font-style: normal;
    font-weight: $font-weight-semibold;
    font-size: $font-size-rem-1xs;
    line-height: 19px;
    text-align: center;
    color: $black-200;
    height: 19px;
    position: relative;
    bottom: 2.25rem;
    }
    .deliverOrderText {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: $font-weight-regular;
        font-size: $font-size-rem-xxs;
        line-height: $font-size-rem-m;
        text-align: center;
        position: relative;
        bottom: 2rem;
        color: $black-200
    }
    .tableText {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: $font-weight-bold;
        font-size: $font-size-rem-1xs;
        line-height: 23px;
        text-align: center;
        color: #183153;
        position: relative;
        bottom: 1.5rem;
        word-break: break-word;
    }
}

.preAuthFooter {
    background: $light-shaded-gray;
    width: auto;
    color: $black-200;
    line-height: 24px;
    text-align: center;
    padding: 0 15px;
    letter-spacing: 0.4px;
    padding-top: 10px;

    p {
        font-size: $font-size-xxs;
        line-height: 16px;
        font-weight: $font-weight-regular;
    }
    
    p.subText {
        line-height: 18px;
        color: $gray-600;
        padding-top: 5px;
    }
}

.logoContainer {
    font-size: $font-size-rem-xxxs;
    margin-top: 100px;

    img {
        width: 85px;
        margin-left: auto;
        margin-right: auto;
    }
}