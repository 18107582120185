@import '../../scss/theme.scss';

.fixedHeader {
	border-bottom: 1px solid $transparent-black;
	padding: 0px;
	background-image: none;
	background-color: $dark-mode-white;
	border-bottom: 1px solid $light-grey;
	height: $full-height;
	width: $full-width;
	position: sticky;
	height: 48px;
	top: -1px;
	box-shadow: 0px 2px 8px 1px $transparent-black;
	overflow: hidden;
	transition: 0.4s;
	z-index: 99;

	.logo {
		padding: 10px 16px;
		align-items: center;
		display: flex;
		color: $black-100;
		text-decoration: none;
		height: 48px;
	}

	.menuLogo {
		background: $dark-mode-white;
		border-radius: 50%;
		max-width: 34px;
		height: 34px;
		margin-right: 10px;
		padding: 3px;
	}

	.navbarRight {
		display: inline;
		visibility: visible;
		font-size: $font-size-l;
		font-weight: $font-weight-bold;
		letter-spacing: 0px;
		line-height: 26px;
		font-family: $font-family;
		color: $black-200;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
