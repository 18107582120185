@import '../../scss/theme.scss';

.cancelIconWithoutBackground {
	margin: 12px 16px;
	width: 34px;
	height: 34px;
}

.cancelIconWithBackground {
	top: 8px;
	right: 11px;
	position: absolute;
	width: 34px;
	height: 34px;
	background-color: $dark-mode-white;
	border-radius: 50%;
}
