@import '../../scss/theme';

.consentModalSection {
	position: fixed;
	top: 0 !important;
	left: 0 !important;
	width: 100%;
	height: 100%;
	text-align: center;
	vertical-align: middle;
	padding: 1em;
	background-color: $modal-background;
	line-height: 1;
	font-family: $font-family;
	z-index: 101;

	.consentModal {
		position: absolute;
		z-index: 1001;
		text-align: left;
		background: $dark-mode-white;
		border: none;
		width: 328px;
		margin: 0 auto;
		top: 50%;
		transform: translate(-50%, -50%);
		border-radius: 4px;
		left: 50%;

		.consentCloseBtn {
			border-radius: 50%;
			float: right;
			margin: 0.5em 0.7em;
			background: #0000000A;
		}
		.modalHeader {
			font-size: $font-size-rem-xl;
			text-align: center;
			font-weight: $font-weight-regular;
			padding: 20px 0px 20px 0px;
			color: $black-100;
		}
		.modalBody {
			.modalContent {
				padding: 16px 24px;
				font-size: $font-size-m;
				line-height: 24px;
				height: 260px;
				overflow: auto;
			}
			.closeBtn {
				width: 90%;
				margin: 0px auto 16px auto;
				border-radius: 6px;
				min-height: 52px;
				background: $primary-blue;
				color: $white-100;
			}
			.consentText {
				margin: 0px 18px 18px;
				line-height: 16px;
				font-size: 12px;
				color: $gray-200;

			}
		}
	}
}

