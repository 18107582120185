@import '../../scss/theme';

.itemHeaderMainBlock {
	overflow: hidden;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
	min-height: 132px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	display: block;	
}

.itemHeaderImage {
	width: 100%;
	background-position: center center;
	background-size: cover;
}

//TODO: https://jira.shift4payments.com/browse/STQR-261 'changes required for word wrap the content properly'

.itemTitle {
	width: calc(100% - $cancel-icon-width);
	font-family: $font-family;
	font-size: $font-size-xxl;
	font-style: normal;
	font-weight: $font-weight-bold;
	padding: 0px 16px;
	text-align: left;
	color: $light-greyishblue;
	line-height: 30px;
}

.itemDescription {
	font-family: $font-family;
	font-size: $font-size-xs;
	font-style: normal;
	text-align: left;
	color: $black-300;
	padding: 0px 16px;
	line-height: 21px;
	word-break: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2;
}

.itemPrice {
	padding: 10px 0px 10px 16px;
	font-family: $font-family;
	font-style: normal;
	font-weight: $font-weight-bold;
	font-size: $font-size-m;
	color: $gray-300;
	line-height: 24px;
	text-align: left;
}

.badgeBlock {
	margin-left: 16px;
	margin-right: 16px;
	margin-top: 14px;
	text-align: left;
}

.itemDefaultPrice {
	padding: 10px 0px 10px 16px;
	font-family: $font-family;
	font-style: normal;
	font-weight: $font-weight-bold;
	font-size: $font-size-m;
	color: $gray-300;
	line-height: 24px;
	text-align: left;
}
