@import '../../scss/theme.scss';

.notificationContainer {
	position: fixed;
	bottom: 0;
	z-index: $z-index-9;
	width: 100%;
}

.notificationUp {
	transform: translateY(-4em);
	transition: .25s ease-in-out;
}

.notificationContainer p {
	line-height: 21px;
}

.notificationDown {
	transform: translateY(0);
	transition: .25s ease-in-out;
}

.notification {
	padding: 20px 16px;
	background: $light-shaded-black;
	color: $dark-mode-white;
	margin: 10px 6px;
	box-shadow: 0px 2px 4px $gray-200;
}

.notificationMessage {
	margin: 0;
	text-align: center;
	font-size: $font-size-xs;
	font-weight: $font-weight-regular;
}

@keyframes toastTop {
	from {
		transform: translate(5%, 100%);
	}
	to {
		transform: translate(5%, 0%);
	}
}

@keyframes toastBottom {
	from {
		transform: translate(0%, 0%);
	}
	to {
		transform: translate(5%, 100%);
	}
}
