@import '../../scss/theme.scss';

.spin {
	background-color: rgba(0, 0, 0, 0.6);
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 100;

	&:before {
		animation-name: spinner;
		animation-duration: 4000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		border: solid 5px #cfd0d1;
		border-bottom-color: #1c87c9;
		border-radius: 50%;
		content: '';
		height: 40px;
		width: 40px;
		position: absolute;
		top: 47.5%;
		left: 47.5%;
		transform: translate3d(-50%, -50%, 0);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
