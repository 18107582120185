@import '../../scss/theme';

.ticketDetailsPageContainer {
	width: 100%;
	padding: 20px;
	background-color: $light-shaded-gray;
	position: relative;
	font-family: $font-family;
	min-height: 100vh;
	.ticketDetailsPage {
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding-bottom: 150px;
	}
	.navButton {
		button {
			padding: 0px !important;
			background-color: transparent !important;
			font-size: $font-size-l;
			float: right;
			i {
				opacity: 1 !important;
				color: $nav-btn !important;
			}
		}
	}
	.logo {
		display: block;
		text-align: center;
		.restaurantLogo {
			border: 1px solid $mods-gray;
			background: $dark-mode-white;
			border-radius: 50%;
			width: 130px;
			height: 130px;
		}
	}
	.restaurantName {
		text-align: center;
		font-weight: $font-weight-semibold;
		font-size: $font-size-xl;
		line-height: 28px;
		margin-block-start: 9px;
		margin-block-end: 9px;
	}
	.ticketAvailable {
		display: block;
	}
	.ticketNotAvailable {
		display: block;
		text-align: center;
		.serverErrorImg {
			width: 50px;
		}
		.contactServerMsg {
			font-weight: $font-weight-bold;
			font-size: $font-size-xl;
			line-height: 28px;
			margin-block-start: 15px;
		}
	}
	.customerMsg {
		width: 100%;
		text-align: center;

		.menuErrorImg {
			width: 50px;
		}
		.customerInfo {
			font-family: $font-family;
			font-weight: $font-weight-bold;
			line-height: 28px;
			font-size: $font-size-rem-xl;
			margin-top: 10px;
			color: $black-200;
		}
	}

	.tableDetails {
		font-family: $font-family;
		font-weight: $font-weight-bold;
		line-height: 23px;
		font-size: $font-size-rem-s;
		color: $black-200;
	}
	.ticketsSection {
		padding-bottom: 130px;
	}
	.ticketDetails {
		display: flex;
		flex-flow: column;

		li {
			display: block;
			ul {
				display: flex;
				align-items: center;
				list-style: none;
				width: 100%;
				line-height: 24px;
				justify-content: space-between;
				&.detailsHeader {
					li {
						font-size: $font-size-rem-xxs;
						color: $black-300;
						line-height: 20px;
						margin-bottom: 6px;
						&.total {
							text-align: left;
						}
					}
				}
				&.detailsContent {
					line-height: 44px;
					border-top: 1px solid $gray-100;
				}
				li {
					margin: 0;
					padding: 0;
					font-size: $font-size-rem-s;
					&.actions {
						width: 30px;
						input[type="radio"]{
							height: 20px;
							width: 20px;
							position: relative;
							top: 5px;
							left: 6px;
						}
					}
					&.name {
						min-width: 30%;
					}
					&.total {
						min-width: 30%;
						text-align: left;
						font-weight: $font-weight-semibold;
					}
					&.time {
						min-width: 30%;
						text-align: left;
						font-weight: $font-weight-semibold;
						color: $black-200;
						line-height: 23px;
					}
					&.totalValue {
						min-width: 30%;
						text-align: left;
						font-weight: $font-weight-regular;
						line-height: 23px;
						color: $black-200;
						font-size: 16px;
					}
					&.timeValue {
						min-width: 30%;
						text-align: left;
						font-weight: $font-weight-semibold;
						line-height: 23px;
						color: $black-200;
						font-size: 16px;
					}
				}
			}
		}
	}
}
.footer {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: fixed;
	padding: 10px;
	bottom: 0px;
	background: $white-100;
	border-top: 1px solid rgba(0, 0, 0, 0.12);
	padding-right: 16px;
	padding-left: 16px;
	.fullWidth {
		width: 100%;
	}
	.btn {
		border-radius: 8px;
		color: $gray-400;
		border-radius: 6px;
		font-family: $font-family;
		font-style: $font-style-normal;
		font-weight: $font-weight-bold;
		font-size: $font-size-m;
		line-height: 24px;
		align-items: center;
		text-align: center;
		cursor: pointer;
		margin-bottom: 8px;
		height: 48px;
		background-color: $light-grey;
	}
	.btnOutlineBlue {
		background-color: $dark-mode-white;
		color: $primary-blue;
		border: 1px solid $primary-btn;
		&:hover {
			background-color: $primary-btn;
			color: $dark-mode-white;
			border: 1px solid $primary-btn;
		}
	}
	.btnDisabled {
		background-color: $loader-gray;
		color: $gray-400;
		border: 1px solid $gray-100;
	}
	.btnEnabled {
		background-color: $primary-blue;
		color: $dark-mode-white;
		border: 1px solid $primary-btn;
		&:hover {
			background-color: $primary-btn;
			color: $dark-mode-white;
			border: 1px solid $primary-btn;
		}
		&:active {
			background-color: $primary-btn;
			color: $dark-mode-white;
			border: 1px solid $primary-btn;
		}
	}
}
