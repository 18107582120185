//Theme color types
$dark-mode-white: #ffffff !default;

//base colors
$black-100: #000000 !default;
$black-200: #1a1a1a !default;
$black-300: #666666 !default;
$gray-100: #cccccc !default;
$gray-200: #6e7073 !default;
$gray-300: #4d4d4d !default;
$gray-400: #a6a6a9 !default;
$gray-500: #667085 !default;
$gray-600: #565656 !default;
$gray-700: #344054 !default;
$white-100: #f2f3f4 !default;
$white-200: #f5f5f5 !default;
$white-300: #e5e5e5 !default;

// menu item section
$success: #0a801d !default;
$required: #e31c1c !default;

//badges
$badge-bgcolor-gray: #746f6f !default;
$badge-bgcolor-purple: #522f69 !default;
$badge-bgcolor-red: #bb0032 !default;
$badge-bgcolor-green: #14665c !default;

//buttons
$success-btn: #2e8540 !default;
$error-btn: #d83520 !default;
$primary-btn: #136ef6 !default;
$nav-btn: #253746 !default;
$error-text: #fd3467 !default;

//loyalty rewards page btn
$rewards-page: #1f2438 !default;
$reward-text: #63e6be !default;

//button colors active and disabled
$active-blue: #136ef6 !default;
$active-green: #0f7b40 !default;
$active-red: #d83520 !default;

$disabled-blue: #a1c5fb !default;
$disabled-green: #abceb3 !default;
$disabled-red: #efaea6 !default;
$disabled-gray: #cccccc !default;

//icon colors
$icon-gray: #888888 !default;

//labels
$recalled: #137ad1 !default;
$dark-blue: #1f5099;

//extra variations
$light-grey: #cccccc;
$bright-gray: #f2f2f2;
$shaded-gray: #ececec;
$light-shaded-gray: #f8f9fa;
$transparent-black: rgba(0, 0, 0, 0.12) !default;
$modifier-border-offset: 73px;
$modifier-gray: #d9d9d9;
$logo-background: #fff;
$modal-background: rgba(0, 0, 0, 0.6) !default;
$error-background: #BD3430;

//Text color
$light-greyishblue: #374354;
$dark-greyish: #1e242e;
$header-address: #656565;
$light-gray: #e4e4e4;
$viewticket-gray: #e5e5e5;
$loader-gray: #e8e8e8;
$mods-gray: #d9d9d9;
$mods-label: #1a1a1a;
$mods-name: #191919;
$radio-btn: #1b1b1b;
$mods-required: #ef3124;
$mods-selected: #3e9550;
$instruction-gray: #9d9dac;
$instruction-placeholder: #666666;
$instruction-text: #191919;
$instruction-divider: #b2b2b2;
$light-shaded-black: #333333;
$light-black: #262626;
$dark-gray: #4b4b4b;
$deep-blue: #183153;
$dark-black: #131313;
$primary-blue: #007aff;

//border colors
$border-gray: #d9d9d9;
$border-gray-selected: #c4c4c4;

// Variables
$v-line-height: 1.4285em;
$menu-option_border-offset: 16px; // menu options text have padding of 21px so to avoid border for padding and to keep only for text content

// Media variables
$media_variables: () !default;
$media_variables: map-merge(
	(
		'portrait-to-landscape-tablet': '(min-width: 768px) and (max-width: 991px)',
		'max-tablets': '(max-width: 991px)',
		'max-phones': '(max-width: 767px)',
		'max-h-md': '(max-height: 500px)',
		'max-h-sm': '(max-height: 250px)',
	),
	$media_variables
);

//z-index
$z-index-1: 100;
$z-index-2: 200;
$z-index-3: 300;
$z-index-4: 400;
$z-index-5: 500;
$z-index-6: 600;
$z-index-7: 700;
$z-index-8: 800;
$z-index-9: 900;
