@import '../../scss/theme';
h1,
h1,
h3,
h4,
h5,
h6,
p {
	margin-block: auto;
}

.menuItems {
	font-family: $font-family;
	display: flex;
	align-items: flex-start;
	padding: 14px 0px 12px 0px;
	justify-content: space-between;
	cursor: pointer;

	.menuItem {
		align-items: center;
		justify-content: center;
	}

	.itemDetail {
		text-align: left;
		margin-right: 0.6em;
	}

	.itemTitle {
		line-height: 23px;
		font-size: $font-size-s;
		font-weight: $font-weight-bold;
		color: $light-greyishblue;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		word-break: break-word;
	}

	.itemDesc {
		font-size: $font-size-xs;
		line-height: 21px;
		color: $black-300;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		word-break: break-word;
		font-weight: $font-weight-regular;
	}

	.itemPrice,
	.itemDefaultPrice {
		font-weight: $font-weight-bold;
		line-height: 23px;
		font-size: $font-size-rem-s;
		margin-top: 4px;
		color: $gray-300;
	}
}
.hideElement {
	display: none;
}

.label {
	font-family: $font-family;
	font-size: $font-size-rem-xxxs;
	font-weight: $font-weight-semibold;
	margin: 0 0.5em 0 0;
	padding: 0.4em;
	min-width: 3em;
	text-align: center;
	max-width: 3.5em;
	max-height: 1.1em;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.medium {
	height: 110px;
	width: 110px;
}

.small {
	height: 70px;
	width: 70px;
}
